import { render, staticRenderFns } from "./ProductPageRelatedProducts.vue?vue&type=template&id=c57442ba&scoped=true&"
import script from "./ProductPageRelatedProducts.vue?vue&type=script&lang=js&"
export * from "./ProductPageRelatedProducts.vue?vue&type=script&lang=js&"
import style0 from "./ProductPageRelatedProducts.vue?vue&type=style&index=0&id=c57442ba&scoped=true&lang=scss&"
import style1 from "./ProductPageRelatedProducts.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c57442ba",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BdAnimatedLoadingIcon: require('/home/node/frontend/node_modules/@bowenstimber/designsystem/components/BdAnimatedLoadingIcon.vue').default,ProductCard: require('/home/node/frontend/components/includes/ProductCard.vue').default})
